import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Inner } from 'styles/structure';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import Logo from 'components/_ui/Logo';
import CleanInput from 'components/_ui/CleanInput';
import Fade from 'react-reveal/Fade';
import Caret from 'vectors/caret-down.svg';
import MobileDrawer from 'components/MobileDrawer';

const HeaderContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  
  & + * {
    margin-top: 7.2rem;
  }
  
  @media(min-width: ${dimensions.desktopTransitionUp}px) {
    & + * {
      margin-top: 9.2rem;
    }
  }
`;

const HeaderBar = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 7.2rem;
  padding: 2.4rem 0 2rem;
  background-color: #fff;
  
  button, 
  a {
    color: #000;
  }
  
  svg {
    fill: #000;
  }
  
  &.is-dark {
    background-color: #000;
    
    button, 
    a {
      color: #fff;
      
      &:last-child {
        color: ${colors.red600};
      }
    }
    
    svg {
      fill: #fff;
    }
  }
  
  .is-sticky & {
    background-color: #fff;
    box-shadow: 0 0.2rem 0.4rem rgba(0,0,0,0.08);
    transition: background 0.16s ease-in-out, box-shadow 0.12s ease-in-out;
  
    button,
    a {
      color: #000;
      transition: color 0.12s ease-in-out;
      
      &:last-child {
        color: ${colors.red600};
      }
    }
    
    svg {
      fill: #000;
      transition: fill 0.12s ease-in-out;
    }
  }
  
  .is-open & {
    background-color: #000;
    
    button, 
    a {
      color: #fff;
      
      &:last-child {
        color: ${colors.red600};
      }
    }
    
    span {
      background-color: #fff;
      
      &::before,
      &::after {
        background-color: #fff;
      }
    }
    
    svg {
      fill: #fff;
    }
  }
  
  @media(min-width: ${dimensions.desktopTransitionUp}px) {
    height: 9.2rem;
    
    .has-open-panel & {
      background-color: #000;
      
      button, 
      a {
        color: #fff;
        
        &:last-child {
          color: ${colors.red600};
        }
      }
      
      svg {
        fill: #fff;
      }
    }
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HeaderLinks = styled.div`
  display: none;
  
  @media(min-width: 1000px) {
    display: block;
  }
`;

const HeaderLink = styled.button`
  position: relative;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  
  &:last-child {
    color: ${colors.red600};
    
    &::after {
      content: "";
      display: block;
      width: 50vw;
      height: 0.2rem;
      background-color: ${colors.red600};
      position: absolute;
      top: 0.4rem;
      left: calc(100% + 0.8rem);
    }
  }
  
  & + * {
    margin-left: 2.77vw;
  }
  
  span {
    padding-right: 1.4rem;
  }
  
  svg {
    position: absolute;
    right: 0;
    top: 0.2rem;
    display: block;
    width: 1.4rem;
    height: 0.8rem;
    fill: #000;
    transition: transform 0.08s ease-in;
    transform-origin: center center;
  }
  
  &.is-open {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const HeaderPanel = styled.div`
  display: none;
  position: relative;
  background-color: #000;
  transition: transform 0.12s ease-out, margin 0s 0.12s;
  transform: scaleY(0);
  transform-origin: top center;
  color: #fff;
  padding: 4rem 0 7.2rem;
  margin-top: -100vh;
  
  ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  a {
    position: relative;
    color: #fff;
    font-size: 3.33vw;
    font-weight: 400;
    transition: opacity 0.08s ease-in-out;
    
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -0.6rem;
      width: 100%;
      height: 0.2rem;
      background-color: #fff;
      transform: scaleX(0);
      transition: transform 0.12s ease-in-out, opacity 0.08s ease-in-out;
    }
    
    &:hover {
      opacity: 1 !important;
      
      &::after {
        transform: scaleX(1);
      }
    }
  }
  
  ul:hover {
    a {
      opacity: 0.6;
    }
  }
  
  .has-open-panel & {
    transform: scaleY(1);
    transition: transform 0.2s ease-out 0.08s;
    margin-top: 0;
  }
  
  @media(min-width: 1000px) {
    display: block;
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    a {
      font-size: 4.8rem;
    }
  }
`;

const HeaderHamburger = styled(CleanInput)`
  display: block;
  position: absolute;
  top: 50%;
  right: 2.4rem;
  transform: translateY(-72%);
  height: 2.3rem;
  cursor: pointer;

  > span {
    display: block;
    position: relative;
    width: 2.4rem;
    height: 0.2rem;
    background-color: #000;
    transition: all 0.4s ease;
    transform-origin: center center;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background-color: inherit;
      transition: all 0.4s ease;
    }

    &::before {
      top: -0.6rem;
    }

    &::after {
      top: 0.6rem;
    }
  }
    
  .is-dark & {
    span {
      background-color: #fff;
      
      &::before,
      &::after {
        background-color: #fff;
      }
    }
  }
  
  .is-open & {    
    span {
      transform: rotate(135deg);
      background-color: #fff !important;
      
      &::before,
      &::after {
        top: 0;
        transform: rotate(90deg);
        background-color: #fff !important;
      }
    }
  }
  
  .is-sticky & {
    span {
      background-color: #000;
      
      &::before,
      &::after {
        background-color: #000;
      }
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    right: 3.5rem;
  }
  
  @media(min-width: 1000px) {
    display: none;
  }
`;

const HeaderMobile = styled.div`
  position: absolute;
  top: 7.2rem;
  width: 100%;
  background-color: #000;
  height: calc(100vh - 7.2rem);
  transform: scaleY(0);
  transform-origin: top center;
  transition: transform 0.22s ease-in-out;
  
  .is-open & {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
  }
  
  @media(min-width: 1000px) {
    display: none;
  }
`;

const MobileInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 4rem;
  overflow-y: scroll;
`;

const MobileLinks = styled.ul`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.08s ease-in-out, visibility 0 0.08s;
  
  > li {
    > a {
      display: block;
      color: #fff;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 1.4rem 0;
    }
    
    & + * {
      border-top: 1px solid rgba(255,255,255,0.5);
    }
  }
  
  .is-open & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.12s 0.5s, visibility 0 0.5s;
  }
`;

const MobileContact = styled(Link)`
  position: relative;
  padding-left: 5.6rem;
  color: ${colors.red600};
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 3.2rem;
  
  &::before {
    content: "";
    display: block;
    position: absolute;
    right: calc(100% - 4rem);
    top: 0.6rem;
    width: 50vw;
    height: 0.3rem;
    background-color: ${colors.red600};
  }
`;

const ESC_KEYCODE = 27;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasOpenPanel: false,
      isOpen: false,
      isSticky: false,
      content: '',
    };
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.setState({
      hasOpenPanel: false,
      content: '',
    });
  };

  handleEscape = e => {
    console.log('button pressed');

    if (e.keyCode === ESC_KEYCODE) {
      this.setState({
        hasOpenPanel: false,
        content: '',
      });
    }
  };

  togglePanel = (section) => {
    if (this.state.content !== section) {
      this.setState({
        hasOpenPanel: true,
        content: section,
      });
    }
  };

  closePanel = () => {
    this.setState({
      hasOpenPanel: false,
      content: '',
    });
  };

  toggleMobile = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  onScroll = () => {
    if (!this.state.isSticky && window.pageYOffset >= 20) {
      this.setState({
        isSticky: true,
      });
    } else if (this.state.isSticky && window.pageYOffset < 20) {
      this.setState({
        isSticky: false,
      });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll.bind(this));

    document.addEventListener('onkeydown', this.handleEscape.bind(this), false);
    document.addEventListener('mousedown', this.handleClick, false);

    this.onScroll();
  }

  render() {
    const self = this;

    return (
      <HeaderContainer
        className={`${self.state.hasOpenPanel ? 'has-open-panel' : ''}${self.state.isOpen ? 'is-open' : ''} ${self.state.isSticky ? 'is-sticky' : ''}`}
        onMouseLeave={() => {self.closePanel()}}
        ref={node => (this.node = node)}
      >
        <HeaderBar className={self.props.style ? `is-${self.props.style}` : null}>
          <Inner>
            <HeaderContent>
              <Link to="/">
                <Logo/>
              </Link>

              <HeaderLinks>
                <HeaderLink
                  type="button"
                  onMouseEnter={() => {
                    self.togglePanel('specialties');
                  }}
                  className={self.state.content === 'specialties' ? 'is-open' : null}
                >
                  <span>Specialties</span>
                  <Caret/>
                </HeaderLink>

                <HeaderLink
                  as={Link}
                  activeClassName="Link--is-active"
                  to="/work">
                  Our Work
                </HeaderLink>

                <HeaderLink
                  type="button"
                  onMouseEnter={() => {
                    self.togglePanel('about');
                  }}
                  className={self.state.content === 'about' ? 'is-open' : null}
                >
                  <span>About Us</span>
                  <Caret/>
                </HeaderLink>

                <HeaderLink
                  as={Link}
                  activeClassName="Link--is-active"
                  to="/insights">
                  Insights
                </HeaderLink>

                <HeaderLink
                  as={Link}
                  activeClassName="Link--is-active"
                  to="/newsroom">
                  Newsroom
                </HeaderLink>

                <HeaderLink
                  as={Link}
                  activeClassName="Link--is-active"
                  to="/contact">
                  Get In Touch
                </HeaderLink>
              </HeaderLinks>

              <HeaderHamburger
                as="button"
                type="button"
                onClick={() => {
                  self.toggleMobile();
                }}
              >
                <span></span>
              </HeaderHamburger>
            </HeaderContent>
          </Inner>
        </HeaderBar>

        <HeaderPanel>
          <Inner>
            {this.state.content === 'specialties' && (
              <Fade>
                <ul>
                  <li><Link to="/specialty/public-relations">Public Relations</Link></li>
                  <li><Link to="/specialty/public-affairs">Public Affairs</Link></li>
                  <li><Link to="/specialty/creative-studio">Creative Studio</Link></li>
                </ul>
              </Fade>
            )}

            {this.state.content === 'about' && (
              <Fade>
                <ul>
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link to="/history">History</Link></li>
                  <li><Link to="/culture">Culture</Link></li>
                  <li><Link to="/careers">Careers</Link></li>
                  <li><Link to="/leadership">Leadership</Link></li>
                </ul>
              </Fade>
            )}
          </Inner>
        </HeaderPanel>

        <HeaderMobile>
          <MobileInner>
            <MobileLinks>
              <li>
              <MobileDrawer heading='Specialties'>
                <ul>
                  <li><Link to="/specialty/public-relations">Public Relations</Link></li>
                  <li><Link to="/specialty/public-affairs">Public Affairs</Link></li>
                  <li><Link to="/specialty/creative-studio">Creative Studio</Link></li>
                </ul>
              </MobileDrawer>
              </li>

              <li><Link to="/work">Our work</Link></li>

              <li>
              <MobileDrawer heading='About Us'>
                <ul>
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link to="/history">History</Link></li>
                  <li><Link to="/culture">Culture</Link></li>
                  <li><Link to="/careers">Careers</Link></li>
                  <li><Link to="/leadership">Leadership</Link></li>
                </ul>
              </MobileDrawer>
              </li>

              <li><Link to="/insights">Insights</Link></li>
              <li><Link to="/newsroom">Newsroom</Link></li>
            </MobileLinks>

            <MobileContact to='/contact'>Get in touch</MobileContact>
          </MobileInner>
        </HeaderMobile>
      </HeaderContainer>
    );
  }
}

Header.propTypes = {
  style: PropTypes.string,
};

export default Header;
