//Dimensions for device media queries and layout padding
const dimensions = {
  //pixels
  mobileUp: 400,
  mobileDown: 599,
  tabletPortraitUp: 750,
  tabletLandscapeUp: 900,
  desktopTransitionUp: 1000,
  desktopUp: 1200,
  desktopDesignUp: 1400,
  desktopLargeUp: 1800,
};

export default dimensions;
