import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled';
import { Inner } from 'styles/structure';
import RichText from 'components/RichText';
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';
import CleanInput from 'components/_ui/CleanInput';
import ArrowButton from 'components/_ui/ArrowButton';
import Facebook from 'vectors/facebook.svg';
import Instagram from 'vectors/instagram.svg';
import Linkedin from 'vectors/linkedin.svg';
import Twitter from 'vectors/twitter.svg';
import NewsletterSubscribe from 'components/NewsletterSubscribe';

const FooterContainer = styled.div`
  display: block;
  width: 100%;
  position: relative;

  > div {
    &:first-of-type {
      background-color: #000;
      color: #fff;
      padding: 4rem 0;

      h3 {
        max-width: 80vw;
      }
    }

    &:last-of-type {
      padding: 1.6rem 0;

      small {
        display: block;
        margin-top: 0.4rem;
        color: ${colors.grey700};
        font-size: 1.2rem;
      }
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    > div {
      &:first-of-type {
        padding-top: 6.4rem;

        h3 {
          max-width: 96rem;
        }
      }

      &:last-of-type {
        padding: 2.4rem 0;

        small {
          display: inline-block;
          margin-left: 3.2rem;
          font-size: 1.4rem;
        }
      }
    }
  }
`;

const FooterLogo = styled(Link)`
  display: block;
  position: absolute;
  right: 2.4rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  background-color: ${colors.red600};

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    right: 4rem;
    width: 2.8rem;
    height: 2.8rem;
  }
`;

const FooterArrow = styled(ArrowButton)`
  width: 4.8rem;
  height: 1.7rem;
  fill: ${colors.red600};
`;

const FooterCtas = styled.div`
  margin-top: 5.6rem;
  
  > * + * {
    margin-top: 4rem;
  }
   
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    margin-top: 6.4rem;
    
    > * + * {
      margin-top: 0;
    }
  }
`;

const ContactLink = styled(CleanInput)`
  position: relative;
  display: inline-block;
  margin-top: 1.6rem;

  span {
    color: ${colors.red600};
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1;
    vertical-align: middle;
  }

  svg {
    position: relative;
    top: 0.4rem;
    display: inline-block;
    margin-left: 1.6rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    span {
      font-size: 2.4rem;
    }

    svg {
      margin-left: 2.4rem;
    }
  }
`;

const FooterSocial = styled.ul`
  display: block;
  margin-top: 5.6rem;

  li {
    display: inline-block;

    & + * {
      margin-left: 4rem;
    }
  }

  svg {
    display: block;
    width: 2.6rem;
    height: 2.6rem;
    fill: #fff;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 9.6rem;
  }
`;

const FooterTertiary = styled.ul`
  li {
    display: inline-block;

    & + * {
      margin-left: 1.6rem;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: inline-block;

    li {
      & + * {
        margin-left: 3.2rem;
      }
    }
  }
`;

const TertiaryLink = styled(Link)`
  color: ${colors.grey700};
  font-size: 1.2rem;
  transition: color 0.08s ease-in-out;

  &:hover {
    color: ${colors.red600};
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 1.4rem;
  }
`;

const Footer = props => {
  const footer = props.data.prismic.allFooters.edges[0].node;
  const social = props.data.prismic.allSocials.edges[0].node;

  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <div>
        <Inner>
          {footer.tagline && <RichText render={footer.tagline} />}

          <FooterCtas>
            <div>
              <h5>{footer.newsletter_heading || 'Join the newsletter'}</h5>

              <NewsletterSubscribe variant='footer' />
            </div>

            <div>
              <h5>{footer.contact_heading || 'Contact us'}</h5>

              <ContactLink as={Link} to='/contact'>
                <span>Get in touch</span>

                <FooterArrow short={true} />
              </ContactLink>
            </div>
          </FooterCtas>

          <FooterSocial>
            {social.facebook_username && (
              <li>
                <a
                  href={`https://www.facebook.com/${social.facebook_username}`}
                  target='blank'
                  rel='noopener noreferrer'
                >
                  <Facebook />
                </a>
              </li>
            )}

            {social.instagram_username && (
              <li>
                <a
                  href={`https://www.instagram.com/${social.instagram_username}`}
                  target='blank'
                  rel='noopener noreferrer'
                >
                  <Instagram />
                </a>
              </li>
            )}

            {social.linkedin_username && (
              <li>
                <a
                  href={`https://www.linkedin.com/company/${social.linkedin_username}`}
                  target='blank'
                  rel='noopener noreferrer'
                >
                  <Linkedin />
                </a>
              </li>
            )}

            {social.twitter_username && (
              <li>
                <a
                  href={`https://www.twitter.com/${social.twitter_username}`}
                  target='blank'
                  rel='noopener noreferrer'
                >
                  <Twitter />
                </a>
              </li>
            )}
          </FooterSocial>
        </Inner>
      </div>

      <div>
        <Inner>
          <FooterTertiary>
            {footer.tertiary_links.length > 0 && footer.tertiary_links.map((link, i) => (
              <li key={i}>
                <TertiaryLink as={Link} to={`/page/${link.page._meta.uid}`}>
                  {link.page.title && link.page.title[0].text}
                </TertiaryLink>
              </li>
            ))}
          </FooterTertiary>

          <small>Copyright ©{currentYear} Marino</small>

          <FooterLogo to='/' />
        </Inner>
      </div>
    </FooterContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        prismic {
          allFooters {
            edges {
              node {
                feature_image
                tagline
                newsletter_heading
                contact_heading
                mailchimp_endpoint
                tertiary_links {
                  page {
                    ... on PRISMIC_Simple_page {
                      title
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
          allSocials {
            edges {
              node {
                facebook_username
                instagram_username
                linkedin_username
                twitter_username
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);
