import React from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Vector from '../../vectors/logo.svg';

const LogoContainer = styled.div`
  display: block;
  position: relative;
  
  &::after {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${colors.red600};
    position: absolute;
    left: calc(100% + 0.4rem);
    bottom: 0.5rem;
  }
  
  @media(min-width: ${dimensions.desktopTransitionUp}px) {
    &::after {
      width: 1.2rem;
      height: 1.2rem;
      bottom: 0.6rem;
    }
  }
`;

const LogoWrap = styled.div`
  width: 10rem;
  position: relative;
  overflow: hidden;
  transition: width 0.12s ease-in-out;
  
  .is-dark & {
    svg {
      fill: #fff;
    }
  }
  
  .is-sticky & {
    width: 2.5rem;
    
    svg {
      fill: ${colors.grey900};
    }
  }
  
  .is-homepage & {
    width: 2.5rem;
  }
  
  .is-open &,
  .has-open-panel & {
    svg {
      fill: #fff !important;
    }
  }
  
  svg {
    width: 10rem;
    height: 2.4rem;
    fill: ${colors.grey900};
  }
  
  @media(min-width: ${dimensions.desktopTransitionUp}px) {
    width: 16.8rem;
    
    .is-sticky &,
    .is-homepage & {
      width: 4.2rem;
    }
  
    svg {
      width: 16.8rem;
      height: 4rem;
    }
  }
`;

const Logo = () => (
  <LogoContainer>
    <LogoWrap>
      <Vector/>
    </LogoWrap>
  </LogoContainer>
);

export default Logo;
