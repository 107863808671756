/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import Helmet from "react-helmet";
import { graphql, StaticQuery } from 'gatsby';

class SEO extends Component {
  render() {
    const social = this.props.data.prismic.allSocials.edges[0].node;

    let socialImage;

    if (this.props.image) {
      socialImage = this.props.image.url;
    } else if (social.image) {
      socialImage = social.image.url;
    }

    const siteMetadata = {
      title: this.props.title || social.title || `Marino PR and Strategic Communications`,
      description: this.props.description || social.description || `This is the default description for Marino.`,
      image: socialImage || null,
      siteName: social.siteName || `Marino PR`,
      siteUrl: social.siteUrl || `https://www.marinopr.com/`,
      siteLanguage: `en-US`,
      siteLocale: `en_us`,
      authorName: `Ronik`,
      author: `Ronik | https://www.ronikdesign.com`,
      twitterUsername: social.twitterUsername || `marinopr`,
    };

    return (
      <Helmet
        htmlAttributes={{
          lang: siteMetadata.siteLanguage,
        }}
        title={siteMetadata.title}
        titleTemplate={`%s | ${siteMetadata.siteName}`}
        meta={[
          {
            name: `description`,
            content: siteMetadata.description,
          },
          {
            property: `og:title`,
            content: siteMetadata.title,
          },
          {
            property: `og:description`,
            content: siteMetadata.description,
          },
          {
            property: `og:image`,
            content: siteMetadata.image,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: siteMetadata.title,
          },
          {
            name: `twitter:description`,
            content: siteMetadata.description,
          },
          {
            name: `viewport`,
            content: `width=device-width, user-scalable=no`,
          },
        ]}
      >
        {/* Google Tag Manager */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-YHEDPB24Q3" />

        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            
            function gtag(){dataLayer.push(arguments);} 
            
            gtag('js', new Date()); 
            gtag('config', 'G-YHEDPB24Q3');  
          `}
        </script>

        {this.props.home && <meta name="google-site-verification" content="brNqDR_YawvVl4MAoiF366FB3CFwcZGMfKXDQsS81Xc"/>}
        {this.props.home && <meta name="facebook-domain-verification" content="3dky4fzhp2qkkofs1217miq420kidx" />}

        {/* LinkedIn */}
        <script>
          {`
            _linkedin_partner_id = "3212514";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `}
        </script>

        <script type="text/javascript">
          {`
            (function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();
          `}
        </script>
      </Helmet>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        prismic {
          allSocials {
            edges {
              node {
                title
                description
                image
                site_name
                site_url
                twitter_username
              }
            }
          }
        }
      }
    `}
    render={data => <SEO data={data} {...props} />}
  />
);
