import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { Global } from "@emotion/core";
import resetStyles from "styles/reset";
import globalStyles from "styles/global";
import { typeStyles } from "styles/typography";
/*import dimensions from "styles/dimensions";*/
import Footer from "components/Footer";
import Header from "components/Header";

const LayoutContainer = styled.div`
`

const Layout = ({ children, headerStyle }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => (
      <LayoutContainer>
        <Global styles={[resetStyles, globalStyles, typeStyles]}/>

        <div className="Layout">
          <Header style={headerStyle} />

          <main className="Layout__content">
            {children}
          </main>

          <Footer/>
        </div>

        <noscript>
          <img height="1" width="1" alt="" src="https://px.ads.linkedin.com/collect/?pid=3212514&fmt=gif" />
        </noscript>
      </LayoutContainer>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
