import React, { Component } from "react";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import { Link } from "gatsby";
import { Rubric } from "styles/typography";
import Fade from "react-reveal/Fade";

const CardContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4rem 2.4rem;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  div {
    position: relative;
    background-color: #fff;
    padding: 2.4rem;
    margin-bottom: ${props => props.work && 20}%;
    z-index: 10;
  }

  h4 {
    color: #000;
    font-size: 2.4rem;
    font-weight: 500;
    margin-top: 1.6rem;
  }

  @media (min-width: ${dimensions.desktopTransitionUp}px) {
    padding: 4rem 2.4rem;
    min-height: 40rem;

    &:hover {
      img {
        transform: scale(1.05);
      }

      div {
        padding-bottom: 5.6rem;

      }
    }

    div {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 5.5556vw);
      padding: 4rem 3.2rem;
      transition: padding 0.22s ease-out;
      margin-bottom: ${props => props.work && 20}%;
    }

    img {
      transform: scale(1);
      transform-origin: bottom center;
      transition: transform 0.24s ease-in-out;
    }
  }

  @media (min-width: ${dimensions.desktopDesignUp}px) {
    div {
      width: calc(100% - 8rem);
    }
  }
`;

class InsightCard extends Component {
  render() {
    const { destination, image, title, category, variant } = this.props;

    return (
      <Fade bottom distance="1.6rem" duration={600}>
        <CardContainer
          className={variant ? `Insight--${variant}` : ""}
          to={destination || "/insights"}
          work={variant === "work"}
        >
          {image && <img src={image.url} alt={image.alt} />}

          {(category || title) && (
            <div>
              {category && <Rubric>{category.name}</Rubric>}
              {title && <h4>{title[0].text}</h4>}
            </div>
          )}
        </CardContainer>
      </Fade>
    );
  }
}

export default InsightCard;
