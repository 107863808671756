import React, { useState } from "react";
import ArrorLink from '../components/_ui/ArrowButton'
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import CleanInput from "components/_ui/CleanInput";
import addToMailchimp from "gatsby-plugin-mailchimp";

const NewsletterWrapper = styled.div`
  max-width: 48rem;

  form {
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
    margin-top: 0.4rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: inline-block;

    form {
      margin-top: 1.2rem;
    }

    &.Newsletter--footer {
      width: 33.2rem;
      margin-right: 15.4vw;
    }
  }
`;

const NewsletterInput = styled(CleanInput)`
  display: block;
  width: 100%;
  margin: auto;
  padding: 0.8rem 7.2rem 0.8rem 0.4rem;
  border-bottom: 0.2rem solid #fff;
  font-size: 1.6rem;
  line-height: 1.25;
  color: #fff;

  ::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
    opacity: 0.8;
    padding-left: 0.6rem;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
    opacity: 0.8;
    padding-left: 0.6rem;

    ::placeholder {
      color: #fff;
      opacity: 0.8;
      padding-left: 0.6rem;
    }
  }

  .Newsletter--footer & {
    border-bottom-color: ${colors.red600};
  }
  .Newsletter--insight & {
    @media (min-width: ${dimensions.tabletLandscapeUp}px) {
      padding: 0.8rem 0.4rem;
      margin-right: 12rem;
    }
  }
`;

const NewsletterButton = styled(CleanInput)`
  position: absolute;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const NewsletterArrow = styled(ArrorLink)`
  position: absolute;
  width: 4.8rem;
  right: 0;
  height: 1.7rem;
  top: -1rem;
  fill: #fff;

  .Newsletter--footer & {
    svg {
      fill: ${colors.red600};
    }
  }
  
  .Newsletter--insight & {
    svg {
      fill: #fff;
    }
    
    .arrow-container {
      background-color: #fff;
    }
  }
`;

const StatusContainer = styled.div`
  position: absolute;

  p {
    position: relative;
    display: inline-block;
    margin-top: 0.8rem;
    left: 0;
    margin-left: 0.2rem;
    font-size: 1.6rem;
    color: #fff;
  }

  .Newsletter--footer & {
    p {
      color: ${colors.red600};
    }
  }
  .Newsletter--insight & {
    p {
      color: #fff;
    }
  }
`;

const StatusMessage = styled.p`
  &.StatusMessage--success {
    color: #fff;
  }
  &.StatusMessage--error {
    color: #fff;
  }
`;

const consoleStyles = ["color: black", "background: yellow"].join(";");

export default function NewsletterSubscribe(props) {
  const [email, setEmail] = useState("");
  const [statusMessage, setStatusMessage] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const result = await addToMailchimp(email);
    setStatusMessage(result);
    console.log("%c%s", consoleStyles, result.msg);
    setEmail("");
  };

  return (
    <NewsletterWrapper
      className={props.variant ? `Newsletter--${props.variant}` : ""}
    >
      <form onSubmit={handleSubmit}>
        <NewsletterInput
          type="text"
          placeholder="Your email address"
          id="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />

        <NewsletterButton as="button" type="submit">
          <NewsletterArrow
            variant="subscribe"
            short={true}
          />
        </NewsletterButton>

        <StatusContainer>
          {statusMessage && statusMessage.result === "success" && (
            <StatusMessage className={"Status--success"}>
              <p>{statusMessage.msg}</p>
            </StatusMessage>
          )}
          {statusMessage && statusMessage.result === "error" && (
            <StatusMessage className={"Status--error"}>
              <p>Please enter a valid email</p>
            </StatusMessage>
          )}
        </StatusContainer>
      </form>
    </NewsletterWrapper>
  );
}
