import styled from "@emotion/styled";
import css from "@emotion/css";
import dimensions from "styles/dimensions";
import colors from "styles/colors";

export const typeStyles = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  span,
  li,
  div,
  input,
  button {
    font-family: "Gotham";
  }
  
  h1 {
    font-size: 3.2rem;
    line-height: 1.25;
    font-weight: 600;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 8rem;
      line-height: 1.2;
    }
  }
  
  h2 {
    font-size: 3.2rem;
    line-height: 1.25;
    font-weight: 500;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 4.8rem;
      line-height: 1.2;
    }
  }
  
  h3 {
    font-size: 3.2rem;
    line-height: 1.25;
    font-weight: 500;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 4rem;
      line-height: 1.32;
    }
  }
  
  h4 {
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 500;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 3.2rem;
    }
  }
  
  h5 {
    font-size: 1.6rem;
    line-height: 1.33;
    font-weight: 500;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 2.4rem;
    }
  }
  
  h6 {
    font-size: 1.6rem;
    line-height: 1.33;
    font-weight: 500;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 2rem;
    }
  }
  
  p {
    font-size: 1.6rem;
    line-height: 1.44;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 1.8rem;
    }
  }
  
  a {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Rubric = styled.span`
  display: block;
  color: ${colors.orange600};
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 1.4rem;
  }
`;
