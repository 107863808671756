import React from 'react';
import RichText from 'components/RichText';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';
import Fade from "react-reveal/Fade";
import {lineAnimate} from "styles/animations";

const ContentContainer = styled.div`
  > * + * {
    margin-top: 3.2rem;
  }
  
  strong {
    font-weight: 500;
  } 
  
  em {
    font-style: italic;
  }
  
  p + p {
    margin-top: 1.6rem;
  }
  
  > h1,
  > h2,
  > h3 {
    & + p {
      margin-top: 2.4rem;
    }
  }
  
  > h4,
  > h5,
  > h6 {
    & + p {
      margin-top: 0.6rem;
    }
  }
  
  a {
    display: inline;
    position: relative;
    color: inherit;
    background-image: linear-gradient(${colors.red600}, ${colors.red600});
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 0.2rem;
    padding-bottom: 0.2rem;
    
    &:hover {
      animation: ${lineAnimate} 0.5s;
    }
  }
  
  ul, ol {
    color: ${colors.grey800};
    font-size: 1.6rem;
    padding-left: 3.2rem;
   
    li {
      & + * {
        margin-top: 1.6rem;
      }
    } 
  }
  
  ol {
    list-style: decimal;
  }
  
  ul {
    list-style: none;
    
    li {
      position: relative;
      
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: calc(100% + 1.6rem);
        top: 0.4rem;
        width: 1.2rem;
        height: 1.2rem;
        background-color: ${colors.red600};
      }
    }
  }
  
  .embed {
    position: relative;
    margin: 5.6rem 0;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    
    > div {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    
    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
  
  .block-img {
    margin: 4.8rem 0;
    
    img {
      display: block;
      max-width: 100%;
    }
    
    p + &,
    & + * {
      margin-top: 3.2rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    > * {
      & + * {
        margin-top: 4rem;
      }
    }
    
    p + p {
      margin-top: 2.4rem;
    }
    
    > h1,
    > h2,
    > h3 {
      & + p {
        margin-top: 3.2rem;
      }
    }
    
    > h4,
    > h5,
    > h6 {
      & + p {
        margin-top: 1.6rem;
      }
    }
    
    ul {
      li {
        & + * {
          margin-top: 2.4rem;
        }
      }
    }
    
    li,
    p {
      font-size: 1.8rem;
      line-height: 1.55;
    }
    
    .block-img {
      margin: 4.8rem 0;
      
      p + &,
      & + * {
        margin-top: 4rem;
      }
    }
  }
`;

const Content = ({ content }) => {
  return (
    <Fade bottom distance='3.2rem'>
      <ContentContainer>
        <RichText render={content}/>
      </ContentContainer>
    </Fade>
  );
};

export default Content;
