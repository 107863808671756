import styled from "@emotion/styled";
import dimensions from "styles/dimensions";

const fractionStrToDecimal = str => str.split('/').reduce((p, c) => p / c);

export const Inner = styled.div`
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  max-width: 140rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  
  @media(min-width: ${dimensions.desktopLargeUp}px) {
    max-width: 148rem;
  }
`;

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 110.6rem;
  margin-left: auto;
  margin-right: auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 1fr;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 1.2fr 1fr;
  }
  
  @media (min-width: ${dimensions.desktopLargeUp}px) {
    max-width: 160rem;
  }
`;

export const gridColumns = (column, gap) => {
  const columnWidth =  column ? column.toString() : '1/2';
  const gapWidth = gap ? `${(1 - fractionStrToDecimal(column)) * parseFloat(gap)}rem` : '1.6rem';

  return `
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * ${columnWidth} - ${gapWidth});
    width: calc(99.9% * ${columnWidth} - ${gapWidth});
  `;
};
