import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Caret from "vectors/caret-right.svg";

const ButtonContainer = styled.button`
  display: inline-block;
  position: relative;
  padding: 0.8rem 7.2rem 0.8rem 0;

  span {
    color: ${colors.red600};
    font-size: 1.8rem;
    font-weight: 500;
  }

  div {
    position: absolute;
    left: calc(100% - 4.8rem);
    /* // visual centering */
    top: 42%;
    width: 4.8rem;
    height: 0.2rem;
    background-color: ${colors.red600};
    transition: width 0.12s ease-in-out;
  }

  svg {
    display: block;
    position: absolute;
    right: -0.2rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0.9rem;
    height: 1.7rem;
    fill: ${colors.red600};
  }

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${dimensions.tabletPortraitUp}px) {
    &:hover {
      div {
        width: ${p => p.short ? 5.4 : 8}rem;
      }
    }

    span {
      font-size: 1.8rem;
    }

    div {
      /* visual centering */
      top: 44%;
    }
  }
`;

class ArrowButton extends Component {
  render() {
    const { text, onClick, short, variant, ...props } = this.props;

    return (
      <ButtonContainer
        onClick={onClick}
        variant={variant}
        short={short}
        {...props}
      >
        {text && <span>{text}</span>}

        <div className="arrow-container">
          <Caret />
        </div>
      </ButtonContainer>
    );
  }
}

export default ArrowButton;
