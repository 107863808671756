/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import styled from "@emotion/styled";
import CleanInput from 'components/_ui/CleanInput';
import Caret from "vectors/caret-down.svg";

const DrawerContainer = styled.div`
  display: block;
  
  header {
    position: relative;
    padding: 1.8rem 0 1.4rem;
    
    h4 {
      color: #fff;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  
  &.is-open {
    header {
      button {
        transform: translateY(-40%) rotate(180deg);
      }
    } 
  }
`;

const DrawerContent = styled.div`
  height: 0;
  opacity: 0;
  visibility: hidden;
  
  .is-open > & {
    height: auto;
    padding-top: 0.8rem;
    padding-bottom: 2.4rem;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out 0.1s;
  }
  
  li {
    & + * {
      margin-top: 1.6rem;
    }
  }
  
  a {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.8rem 2.4rem;
  }
`;

const DrawerToggle = styled(CleanInput)`
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  transition: transform 0.12s ease-out;
  transform-origin: center;
  
  svg {
    width: 1.4rem;
    height: 0.9rem;
    fill: #fff;
  }
`;

class Drawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }
  }

  toggleDrawer = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const self = this;

    return (
      <DrawerContainer className={`${self.state.isOpen ? 'is-open' : ''}`}>
        <header onClick={() => {self.toggleDrawer()}}>
          <h4>{self.props.heading}</h4>

          <DrawerToggle as='button'>
            <Caret/>
          </DrawerToggle>
        </header>

        <DrawerContent>
          {this.props.children}
        </DrawerContent>
      </DrawerContainer>
    )
  }
}

export default Drawer;
